import {
  CLEAR_PREVIOUS_NETWORK_SETTINGS,
  GET_NETWORK_ACCESS_LIST_FAILURE,
  GET_NETWORK_ACCESS_LIST_REQUEST,
  GET_NETWORK_ACCESS_LIST_SUCCESS,
  GET_NETWORK_SETTINGS_FAILURE,
  GET_NETWORK_SETTINGS_REQUEST,
  GET_NETWORK_SETTINGS_SUCCESS,
  PUT_NETWORK_SETTINGS_FAILURE,
  PUT_NETWORK_SETTINGS_REQUEST,
  SET_COUNTER,
} from "actions/unit";
import { cloneDeep } from "lodash";

const initialState = {
  error: {},
  networkAccessList: [],
  networkAccessLoading: false,
  networkSettingsLoading: false,
  networkSettings: {},
  previousNetworkSettings: {},
  counter: 0,
};

export default function unit(state = initialState, action) {
  const { type, payload } = action;

  const getNetworkSettings = (settings) => {
    if (state.counter > 0 && state.counter < 3) {
      let s = cloneDeep(settings);
      s.status.updating = new Date().getTime();
      return s;
    } else {
      return settings;
    }
  };

  switch (type) {
    case GET_NETWORK_ACCESS_LIST_REQUEST: {
      return {
        ...state,
        networkAccessLoading: true,
      };
    }

    case GET_NETWORK_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        networkAccessLoading: false,
        networkAccessList: payload,
      };
    }

    case GET_NETWORK_ACCESS_LIST_FAILURE: {
      return {
        ...state,
        networkAccessLoading: false,
        networkAccessList: [],
      };
    }

    case GET_NETWORK_SETTINGS_REQUEST: {
      return {
        ...state,
        networkSettingsLoading: true,
      };
    }

    case PUT_NETWORK_SETTINGS_REQUEST: {
      return {
        ...state,
        networkSettingsLoading: true,
        previousNetworkSettings: state.networkSettings,
        counter: 1,
      };
    }

    case SET_COUNTER: {
      return {
        ...state,
        counter: payload,
      };
    }

    case GET_NETWORK_SETTINGS_FAILURE:
    case PUT_NETWORK_SETTINGS_FAILURE: {
      return {
        ...state,
        networkSettingsLoading: false,
      };
    }

    case GET_NETWORK_SETTINGS_SUCCESS: {
      return {
        ...state,
        networkSettings: getNetworkSettings(payload),
        networkSettingsLoading: false,
        counter: state.counter === 0 ? 0 : state.counter + 1,
      };
    }

    case CLEAR_PREVIOUS_NETWORK_SETTINGS: {
      return {
        ...state,
        previousNetworkSettings: {
          status: {
            last_message: 0,
          },
        },
        networkSettings: {
          status: {
            last_message: 0,
          },
        },
      };
    }

    default:
      return state;
  }
}
