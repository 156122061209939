import { getNetworkAccessList } from "actions/unit";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SelectWifiNetwork } from "empower-energy-owner-components";

const SelectWifiNetworkModal = ({
  selectedUnit,
  onCancel,
  onClickSelectWifiNetwork,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.unit?.networkAccessLoading);
  const list = useSelector((state) => state.unit?.networkAccessList);
  const networkSettings = useSelector((state) => state.unit?.networkSettings);

  useEffect(() => {
    if (selectedUnit.id) {
      fetchNetworkList();
    }
  }, [dispatch, selectedUnit]);

  const fetchNetworkList = () => {
    dispatch(getNetworkAccessList(selectedUnit.id));
  };

  return (
    <SelectWifiNetwork
      loading={loading}
      list={list}
      networkSettings={networkSettings}
      onCancel={onCancel}
      onClickSelectWifiNetwork={onClickSelectWifiNetwork}
      onClickSearch={fetchNetworkList}
    />
  );
};

export default SelectWifiNetworkModal;
