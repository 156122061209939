import {
  GET_SITE_DETAILS_REQUEST,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAILURE,
  CLEAR_SITES,
} from "actions/site";

const initialState = {
  loading: false,
  error: {},
  details: {
    online: true,
  },
};

export default function site(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SITE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SITE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case GET_SITE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        details: {},
      };
    }

    case CLEAR_SITES: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
